(function ($) {
	("use strict");

	/**
	 * Handles the click event on a swatch option.
	 *
	 * @param {Object} event The click event.
	 */
	$(document).on("click", ".cfvsw-swatches-option", function (event) {
		// Get the swatch option element.
		const swatchesOption = $(this);

		// Get the parent element.
		const parent = swatchesOption.parent();

		// Remove the custom selection class from all swatches in the parent.
		parent.find(".cfvsw-swatches-option").each(function () {
			$(this).removeClass("cfvsw-selected-swatch-custom");
		});

		// Return if the swatch option is disabled or out of stock.
		if (
			// If the page is the shop page, or
			$("body").hasClass("woocommerce-shop") ||
			// the swatch option has the disabled or out of stock class.
			swatchesOption.hasClass("cfvsw-swatches-disabled") ||
			swatchesOption.hasClass("cfvsw-swatches-out-of-stock")
		) {
			return;
		}

		// If the swatch option is already selected, remove the selection class.
		if (swatchesOption.hasClass("cfvsw-selected-swatch")) {
			swatchesOption.removeClass("cfvsw-selected-swatch");
			// Add a custom selection class.
			swatchesOption.addClass("cfvsw-selected-swatch-custom");
		} else {
			// Add the selection class to the swatch option.
			swatchesOption.addClass("cfvsw-selected-swatch");
		}
	});

	$(".woocommerce-shop .product-type-variable").on(
		"click",
		".cfvsw-swatches-option",
		function () {
			const color = $(this).data("slug");
			const url = $(this)
				.parents("li")
				.find(".product-wrap > a")
				.attr("href");

			window.location.href = `${url}?attribute_pa_color=${color}`;
		}
	);
})(jQuery);
