// core version + navigation, pagination modules:
import Swiper from "swiper";
import { Manipulation, Navigation, Pagination } from "swiper/modules";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";

(function ($) {
	"use strict";

	const $rxjrGallery = [];

	$(document).on("show_variation", (element, variation) => {
		const variationId = parseInt(variation.variation_id, 10);

		if (!$rxjrGallery.find((o) => (o.variation_id = variationId))) {
			return;
		}

		$(".product-thumbnails").trigger("swiper-init", [variationId]);
	});

	$(".product-thumbnails").on("swiper-init", (event, variationId) => {
		const container = $(".product-thumbnails");

		const flexSlider = container
			.next(".images")
			.find(`.woocommerce-product-gallery--variation-${variationId}`);

		const gallery = $rxjrGallery.find((o) => o.variationId === variationId);

		if (gallery) {
			const slides = gallery.images.map(
				(o) =>
					`<div class="swiper-slide">
						<div class="swiper-slide-wrapper">
							<img src="${o}" />
						</div>
					</div>`
			);

			// init Swiper:
			const swiper = new Swiper(".swiper", {
				modules: [Navigation, Pagination, Manipulation],
				centeredSlidesBounds: true,
				direction: "horizontal",
				initialSlide: 0,
				loop: false,
				loopAdditionalSlides: 2,
				loopFillGroupWithBlank: false,
				slidesPerGroup: 1,
				slidesPerView: 2,
				spaceBetween: 14,
				watchSlidesProgress: true,
				breakpoints: {
					768: {
						slidesPerView: 4,
					},
					992: {
						slidesPerView: 3,
						direction: "vertical",
					},
				},

				navigation: {
					nextEl: ".swiper-nav-next",
					prevEl: ".swiper-nav-prev",
				},

				on: {
					click: (s, e) => {
						const { clickedIndex } = s;

						if (clickedIndex >= 0) {
							flexSlider.flexslider(clickedIndex);

							container
								.find(".swiper-slide")
								.removeClass("active");

							const slide = container
								.find(".swiper-slide")
								.get(clickedIndex);

							$(slide).addClass("active");
						}
					},
				},
			});
			swiper.removeAllSlides();

			swiper.addSlide(1, slides);
			swiper.initialSlide = 0;
			swiper.navigation.enabled = gallery.length > 3;
			swiper.update();

			container.find(".swiper-slide").eq(0).addClass("active");
		}
	});

	$(".product-thumbnails").on("init", (event, variationId) => {
		const container = $(".product-thumbnails");
		const containerWrapper = container.find(".product-thumbnails__wrapper");

		containerWrapper.remove();
		container.html(`<div class="product-thumbnails__wrapper"></div>`);

		const gallery = $rxjrGallery.find((o) => o.variationId === variationId);

		if (gallery) {
			const listImages = gallery.images.map(
				(o) => `<li><img src="${o}" /></li>`
			);

			const list = $("<ul class='slides'>").html(listImages);

			container.find(".product-thumbnails__wrapper").html(list);
		}
	});

	$(document).ajaxSuccess(function (event, request, settings) {
		const { url, data } = settings;
		const { responseJSON } = request;

		if (url.includes("wc_additional_variation_images_get_images")) {
			const params = new URLSearchParams(data);
			const variationId = parseInt(params.get("variation_id"), 10);

			// Create a jQuery object from the HTML string
			var $html = $("<div>").html(responseJSON.main_images);

			// Extract `src` attributes with 200w from `srcset`
			const images = $html
				.find("img")
				.map(function () {
					var srcset = $(this).attr("srcset");
					if (srcset) {
						var srcsetEntries = srcset.split(", ");
						for (var i = 0; i < srcsetEntries.length; i++) {
							var parts = srcsetEntries[i].split(" ");
							if (parts[1] === "200w") {
								return parts[0];
							}
						}
					}

					return $(this).attr("src");
					// return null;
				})
				.get();

			$rxjrGallery.push({ variationId, images });

			const waitForElement = (
				selector,

				interval = 100,
				maxRetries = 50
			) => {
				let retries = 0;

				const checkExistence = () => {
					if ($(selector).length > 0) {
						$(".product-thumbnails").trigger("swiper-init", [
							variationId,
						]);
					} else if (retries < maxRetries) {
						retries++;
						setTimeout(checkExistence, interval); // Retry after the interval
					} else {
						console.warn(
							"Element not found after maximum retries."
						);
					}
				};

				checkExistence();
			};

			const activeGallery = `.woocommerce-product-gallery--variation-${variationId}`;

			waitForElement(activeGallery, 200, 30); // Check every 200ms, max 30 times
		}
	});

	$(document).ajaxComplete(function (event, request, settings) {
		const { url } = settings;

		if (url.includes("shop/page/")) {
			$(".rxjr-product-slider").each((indx, html) => {
				$(".rxjr-product-slider").trigger("product-slider", [html]);
			});

			$(document).trigger("yith_wcwl_init");
		}
	});

	$(".rxjr-product-slider").on("product-slider", (element, html) => {
		const $slider = $(html);

		const $slideCount = $slider.find(
			".rxjr-product-slider__wrapper > div"
		).length;

		$slider.flexslider({
			animation: "slide",
			directionNav: $slideCount > 1,
			controlNav: false,
			animationLoop: false,
			loop: false,
			slideshow: false,
			selector: ".rxjr-product-slider__wrapper > div",
			prevText: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.2 17"><path d="M9.2 16.3 1.4 8.5 9.2.7 8.5 0 0 8.5 8.5 17l.7-.7z" stroke="currentColor" stroke-width:0"/></svg>`,
			nextText: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.2 17"><path d="m0 .7 7.8 7.8L0 16.3l.7.7 8.5-8.5L.7 0 0 .7z" stroke="currentColor" stroke-width:0"/></svg>`,

			start: (e) => {
				e.find(".rxjr-product-gallery__image").show();
			},
		});
	});

	$(window).load(() => {
		// $(".rxjr-product-slider").trigger("product-slider");

		$(".rxjr-product-slider").each((indx, html) => {
			$(".rxjr-product-slider").trigger("product-slider", [html]);
		});
	});

	$(".variations_form").on(
		"woocommerce_variation_select_change",
		function () {
			const $additionalInfo = $(".additional-info");
			$additionalInfo.empty();

			setTimeout(() => {
				const $description = $(".woocommerce-variation-description");

				$additionalInfo.append($description.show());

				// $description.appendTo($additionalInfo);
			});
		}
	);
})(jQuery);
