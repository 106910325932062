import intlTelInput from "intl-tel-input";

const input = document.querySelector(".intl-phone");

if (input) {
	intlTelInput(input, {
		utilsScript: "/node_modules/intl-tel-input/build/js/utils.js",
		fixDropdownWidth: false,
		initialCountry: "auto",
		geoIpLookup: (success, failure) => {
			fetch("https://ipapi.co/country_code")
				.then((res) => res.text())
				.then(success)
				.catch(failure);
		},
	});
}
